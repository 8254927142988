* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Satoshi', sans-serif !important;
}
.App {
  font-family: 'Satoshi', sans-serif !important;
  background-color: #F5F5F5;
  overflow-x: hidden;
  width: "100vw";
}
